<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <p>Assign formats to customers</p>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="customers.length">
              <button
                class="btn btn-white btn-dim btn-outline-primary"
                @click.prevent="creatingNew"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Assign </span>
                  Product
                </span>
              </button>
            </li>
            <li v-if="customers.length">
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Customer
                  </span>
                </div>
                <Multiselect
                  :options="customers"
                  v-model="record.user_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchUserAssignments"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div
        class="card"
        :class="{
          'card-bordered': creating || assignments.data.length
        }"
        v-else
      >
        <div class="card-header" v-if="creating && record.user_id">
          <div class="row">
            <div class="col-md-5">
              <div class="ng-block-des d-print-none">
                <Multiselect
                  class="bg-white"
                  :options="products"
                  v-model="record.product_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Select Product..."
                />
              </div>
            </div>
            <div class="col-md-7" v-if="record.product_id">
              <a
                href="#assignProduct"
                data-toggle="modal"
                class=" btn btn-dim btn-outline-primary float-right"
              >
                <span>
                  <span class="d-none d-sm-inline-block">
                    Assign {{ product?.name }} to
                  </span>
                  {{ customer?.name }}
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="assignProduct">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        <span>
                          <span class="d-none d-sm-inline-block">
                            Assign to
                          </span>
                          {{ customer?.name }}
                        </span>
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="col-md-12 form-group">
                        <label>
                          Generated Format Name
                        </label>
                        <input
                          class="form-control"
                          :value="record?.name"
                          disabled
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Primary Packaging
                        </label>
                        <Multiselect
                          :options="getCosts('primary-packaging')"
                          v-model="record.primary"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Box Type
                        </label>
                        <Multiselect
                          :options="getCosts('box-types')"
                          v-model="record.box"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="control-label">
                          Label
                        </label>
                        <Multiselect
                          :options="getCosts('labelling')"
                          v-model="record.label"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Pack Count</label>
                        <input
                          class="form-control"
                          v-model="record.count"
                          type="number"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Pack Weight(g)</label>
                        <input
                          class="form-control"
                          v-model="record.weight"
                          type="number"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Gross Weight(g)</label>
                        <input
                          class="form-control"
                          v-model="record.gross_weight"
                          type="number"
                        />
                      </div>

                      <div class="col-md-6 form.group">
                        <label>
                          Product
                        </label>
                        <input
                          class="form-control"
                          :value="product?.name"
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label v-if="customer.contact?.currency == 'euro'">
                          Price(€)
                        </label>
                        <label
                          v-else-if="customer.contact?.currency == 'pound'"
                        >
                          Price(£)
                        </label>
                        <label
                          v-else-if="customer.contact?.currency == 'dollar'"
                        >
                          Price($)
                        </label>
                        <label v-else>
                          Price(KSh)
                        </label>
                        <input
                          class="form-control"
                          v-model="record[customer.contact?.currency || 'euro']"
                          @keyup="setName"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="modal-footer bg-light justify-content-between">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="ractive"
                          v-model="record.active"
                        />

                        <label
                          class="custom-control-label"
                          for="ractive"
                          v-if="record.active"
                        >
                          Active
                        </label>
                        <label
                          class="custom-control-label"
                          for="ractive"
                          v-else
                        >
                          Inactive
                        </label>
                      </div>
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Assignment
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="alert alert-warning text-center"
          v-else-if="creating && !record.user_id"
        >
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a customer to assign.
        </div>

        <table class="table table-tranx" v-if="assignments.data?.length">
          <thead class="text-capitalize">
            <tr class="tb-tnx-head">
              <th class="tb-tnx-info">
                <input
                  type="text"
                  class="form-control d-print-none"
                  placeholder="Search Product"
                  v-model="search"
                  @keyup="searchProducts"
                />
              </th>
              <th class="align-middle">
                Primary Packaging
              </th>
              <th class="align-middle">Box Type</th>
              <th class="align-middle">Label</th>
              <th class="align-middle">
                Pack Count
              </th>
              <th class="align-middle">
                Pack Weight(g)
              </th>
              <th class="align-middle">
                Gross Weight(g)
              </th>
              <th class="align-middle">
                <span v-if="customer.contact?.currency == 'euro'">
                  Price(€)
                </span>
                <span v-if="customer.contact?.currency == 'pound'">
                  Price(£)
                </span>
                <span v-else-if="customer.contact?.currency == 'dollar'">
                  Price($)
                </span>
                <span v-else-if="customer.contact?.currency == 'shilling'">
                  Price(KSh)
                </span>
              </th>
              <th class="text-center align-middle">
                Active
              </th>
              <th class="tb-odr-action d-print-none">
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(f, i) in assignments.data" :key="i" class="tb-tnx-item">
              <td class="tb-tnx-info align-middle">
                <div class="tb-tnx-desc">
                  <span class="title">
                    {{ f.name }}
                  </span>
                </div>
              </td>
              <td class="align-middle" style="min-width: 180px;">
                <div class="form-group">
                  <Multiselect
                    :options="getCosts('primary-packaging')"
                    v-model="f.primary"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    :searchable="true"
                    placeholder="Type to search..."
                    @select="updateRecord(f)"
                  />
                </div>
              </td>
              <td class="align-middle" style="min-width: 180px;">
                <div class="form-group">
                  <Multiselect
                    class="border-0"
                    :options="getCosts('box-types')"
                    v-model="f.box"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    :searchable="true"
                    placeholder="Type to search..."
                    @select="updateRecord(f)"
                  />
                </div>
              </td>
              <td class="text-center" style="min-width: 180px;">
                <div class="form-group">
                  <Multiselect
                    class="border-0"
                    :options="getCosts('labelling')"
                    v-model="f.label"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    :searchable="true"
                    placeholder="Type to search..."
                    @select="updateRecord(f)"
                  />
                </div>
              </td>
              <td class="text-center" style="min-width: 60px;">
                <div class="form-control-wrap">
                  <input
                    class="form-control border-0"
                    v-model="f.count"
                    type="number"
                    @change="updateRecord(f)"
                  />
                </div>
              </td>
              <td>
                <div class="input-group border-0">
                  <input
                    class="form-control border-0"
                    type="number"
                    v-model="f.weight"
                    @change="updateRecord(f)"
                  />
                </div>
              </td>
              <td>
                <div class="input-group border-0">
                  <input
                    class="form-control border-0"
                    type="number"
                    v-model="f.gross_weight"
                    @change="updateRecord(f)"
                  />
                </div>
              </td>
              <td class="text-center" style="min-width: 80px;">
                <div class="form-control-wrap">
                  <input
                    class="form-control border-0"
                    v-model="f[customer.contact?.currency || 'euro']"
                    type="number"
                    @change="updateRecord(f)"
                  />
                </div>
              </td>
              <td class="text-center">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`active${f.id}`"
                    v-model="f.active"
                    @change="updateRecord(f)"
                  />
                  <label
                    class="custom-control-label"
                    :for="`active${f.id}`"
                  ></label>
                </div>
              </td>
              <td class="align-middle d-print-none">
                <button
                  class="btn btn-sm btn-icon btn-danger btn-round"
                  @click.prevent="deleteRecord(f.id)"
                >
                  <em class="icon ni ni-trash-empty"></em>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <paginate
        :data="assignments"
        @changed="fetchAssignments"
        v-if="assignments.data.length"
      />
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, ref, reactive, onMounted } from "vue";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const search = ref("");
    const headers = {
      count: "Pack Count",
      weight: "Pack Weight"
    };

    const products = ref([]);
    const costs = ref([]);

    const creating = ref(false);

    const customers = ref([]);
    const user_id = ref(0);

    const states = ref([
      { value: "select", label: "Select" },
      { value: "loose", label: "Loose" },
      { value: "prepack", label: "Prepack" }
    ]);

    const record = reactive({
      user_id: 0,
      product_id: 0,
      box: 0,
      primary: 0,
      count: 0,
      weight: 0,
      gross_weight: 0,
      shilling: 0,
      pound: 0,
      dollar: 0,
      name: "",
      active: true
    });

    const product = computed(() => {
      return (
        products.value.find((p: any) => p.id == record.product_id) || {
          name: ""
        }
      );
    });

    const box = computed(() => {
      return (
        costs.value.find((c: any) => c.id == record.box) || {
          name: ""
        }
      );
    });

    const primary = computed(() => {
      return costs.value.find((c: any) => c.id == record.primary);
    });

    const selected = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == record.user_id) || {
          name: ""
        }
      );
    });

    const assignments = ref({
      data: []
    });

    function fD(d: any) {
      return format(new Date(d), "EEE MMM do, yyyy");
    }

    function setName() {
      record.name = `${product?.value?.name} ${record.count}x${record.weight}g`;
    }

    function formatName(record: any): string {
      const n = `${record.product?.name} ${record.count}x${record.weight}g`;
      record.name = n;

      return n;
    }

    function fetchProducts() {
      processing.value = true;
      http.get(`/api/products?fetch=all`).then(res => {
        products.value = res.data;
        processing.value = false;
      });
    }

    function searchProducts() {
      http.get(`/api/products?s=${search.value}`).then(res => {
        products.value = res.data;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function fetchAssignments(page = 1) {
      processing.value = true;

      http
        .get(`/api/assignments?user_id=${record.user_id}&page=${page}`)
        .then((res: any) => {
          assignments.value = res.data;
          processing.value = false;
        });
    }

    function fetchUserAssignments(id: any) {
      user_id.value = id;
      processing.value = true;

      http.get(`/api/assignments?user_id=${id}`).then((res: any) => {
        assignments.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/assignments", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          creating.value = false;
          document.getElementById("hideNewForm")?.click();
          fetchUserAssignments(record.user_id);
        });
    }

    function updateRecord(r: any) {
      r._method = "PUT";
      r.name = formatName(r);

      http
        .post(`/api/assignments/${r.id}`, r)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchUserAssignments(r.user_id);
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/assignments/${id}`, { _method: "DELETE" }).then(res => {
        fetchUserAssignments(user_id.value);
      });
    }

    function getCosts(type: any) {
      return costs.value?.filter((cost: any) => cost?.category?.slug == type);
    }

    function creatingNew() {
      creating.value = true;
    }

    onMounted(() => {
      fetchProducts();
      fetchCustomers();

      http.get(`/api/costs?fetch=all`).then((res: any) => {
        costs.value = res.data;
      });
    });

    return {
      headers,
      searchProducts,
      fetchProducts,
      processing,
      products,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      search,
      customers,
      customer,
      fetchAssignments,
      fetchUserAssignments,
      product,
      costs,
      getCosts,
      states,
      setName,
      formatName,
      assignments,
      box,
      primary,
      creating,
      creatingNew,
      fD
    };
  }
});
</script>
<style scoped>
td{
  padding: 0.5rem 0 0.5rem 0.5rem !important;
}
.form-control {
  padding: 0.4375rem 0.5rem !important;
}
</style>
